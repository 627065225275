<template>
    <div id="app">
        <router-view name="header"></router-view>
        <main :class="`site-lang-${this.lang}`">
            <fade-transition origin="center" mode="out-in" :duration="250">
                <router-view/>
            </fade-transition>
        </main>
        <router-view name="footer"></router-view>

        <!-- <div v-if="show_blackweek_popup" class="black-week-popup">
          <div @click="closeBlackWeekPopup" class="black-week-backdrop"></div>
          <div class="black-week-content">
            <i @click="closeBlackWeekPopup" class="fa fa-window-close" aria-hidden="true"></i>
            <img @click="navigateBlackWeek" :src="require(`@/assets/img/popup-blacknovember.webp`)" alt="Apolar Black Week">
          </div>
        </div> -->
    </div>
</template>
<script>
import { FadeTransition } from "vue2-transitions";

export default {
  metaInfo: {
    titleTemplate: '%s | Apolar',
    htmlAttrs: {
      lang: 'pt-BR'
    }
  },
  components: {
    FadeTransition
  },
  data() {
    return {
      show_blackweek_popup: false
    }
  },
  methods: {
    getTagId() {
    },
    closeBlackWeekPopup() {
      this.show_blackweek_popup = false
      this.$cookies.set('blackweek_done', '1') 
    },
    navigateBlackWeek() {
      this.closeBlackWeekPopup()
      window.open('https://www.apolar.com.br/feirao-apolar', '_blank');
    }
  },
  computed: {
    firefly_widget_id: function() {
        //firefly lancamentos
        if (window.location.href.indexOf('/lancamentos') != -1) {
          return 'cl6uy47u2a7jb0g58wroiqmjq'
        } else {
          //fireflyx principal
          return 'cjxo3bfjf08uq07871kx4kpqy'
        }
    }
  },

  mounted() {
    //this.$cookies.remove('blackweek_done')
    window.onscroll = () => {
        let blackweek_done = this.$cookies.get('blackweek_done')
        if (!blackweek_done && this.lang == 'ptBR') {
          this.show_blackweek_popup = true
        }
      }
  },
  beforeMount(){
    
    
  },
  created() {

      var url = new URL(window.location.href)
      var params = new URLSearchParams(url.search);
      var custom_gtm = params.get('g')

      var head = document.querySelector('head')
      var body = document.querySelector('body')

      var ra_tag_head = document.createElement('script');
      ra_tag_head.type = 'text/javascript';
      ra_tag_head.setAttribute('id', `ra-embed-verified-seal`)
      ra_tag_head.setAttribute('src', `https://s3.amazonaws.com/raichu-beta/ra-verified/bundle.js`)
      ra_tag_head.setAttribute('data-id', `MzYwMDphcG9sYXItaW1vdmVpcw==`)
      ra_tag_head.setAttribute('data-target', `ra-verified-seal`)
      ra_tag_head.setAttribute('data-model', `2`)
      head.append(ra_tag_head);

      if (custom_gtm) {
          custom_gtm = atob(custom_gtm)
          console.log("CUSTOM GTM: ", custom_gtm)

          var gtag_head = document.createElement('script');
          gtag_head.type = 'text/javascript';
          gtag_head.text = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer',"${custom_gtm}");`;
          head.append(gtag_head);

          var gtag_body_noscript = document.createElement('noscript');
          var gtag_body_iframe = document.createElement('iframe');
          gtag_body_iframe.setAttribute('src', `https://www.googletagmanager.com/ns.html?id=${custom_gtm}`)
          gtag_body_iframe.setAttribute("height", "0")
          gtag_body_iframe.setAttribute("width", "0")
          gtag_body_iframe.setAttribute("style", "display:none;visibility:hidden")
          gtag_body_noscript.appendChild(gtag_body_iframe)
          body.appendChild(gtag_body_noscript)
      }


      if ( this.lang == 'es') {

        var paraguay_rdstation = document.createElement('script');
        paraguay_rdstation.type = 'text/javascript';
        paraguay_rdstation.setAttribute('src', `https://d335luupugsy2.cloudfront.net/js/loader-scripts/5e1e6f31-9f14-4573-9574-238a879dacd7-loader.js`);
        paraguay_rdstation.setAttribute('async', '');
        head.append(paraguay_rdstation);

        var paraguay_gtm = document.createElement('script');
        paraguay_gtm.type = 'text/javascript';
        paraguay_gtm.setAttribute('src', `https://www.googletagmanager.com/gtag/js?id=G-FVB8WPE3FD`);
        paraguay_gtm.setAttribute('async', '')
        head.append(paraguay_gtm)

        var paraguay_gtm_script = document.createElement('script');
        paraguay_gtm_script.type = 'text/javascript';
        paraguay_gtm_script.text = `
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', 'G-FVB8WPE3FD');`;
        head.append(paraguay_gtm_script);


        var paraguay_gtm_script_2 = document.createElement('script');
        paraguay_gtm_script_2.type = 'text/javascript';
        paraguay_gtm_script_2.text = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','GTM-W32X6LH3');`;
        head.append(paraguay_gtm_script_2);

        var paraguay_gtm_body_2 = document.createElement('noscript');
        var paraguay_gtm_body_frame_2 = document.createElement('iframe');
        paraguay_gtm_body_frame_2.setAttribute('src', `https://www.googletagmanager.com/ns.html?id=GTM-W32X6LH3`)
        paraguay_gtm_body_frame_2.setAttribute("height", "0")
        paraguay_gtm_body_frame_2.setAttribute("width", "0")
        paraguay_gtm_body_frame_2.setAttribute("style", "display:none;visibility:hidden")
        paraguay_gtm_body_2.appendChild(paraguay_gtm_body_frame_2)
        body.appendChild(paraguay_gtm_body_2)


        var paraguay_gtm_3 = document.createElement('script');
        paraguay_gtm_3.type = 'text/javascript';
        paraguay_gtm_3.setAttribute('src', `https://www.googletagmanager.com/gtag/js?id=AW-16794466468`)
        paraguay_gtm_3.setAttribute('async', '')
        head.append(paraguay_gtm_3)

        var paraguay_gtm_script_3 = document.createElement('script');
        paraguay_gtm_script_3.type = 'text/javascript';
        paraguay_gtm_script_3.text = `
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', 'AW-16794466468');
        `;
        head.append(paraguay_gtm_script_3);

        
      }

      if ( this.lang == 'ptBR') {
        var firefly_script = document.createElement('script');
        firefly_script.type = 'text/javascript';
        firefly_script.text = `window.FireflyChatWidgetId = "${this.firefly_widget_id}"`
        head.append(firefly_script);

        var firefly_link = document.createElement('link');
        firefly_link.type = 'text/css';
        firefly_link.setAttribute('rel', 'stylesheet');
        firefly_link.setAttribute('href', `https://chat-widget-static.firefly.chat/chat.css`)
        head.append(firefly_link);

        var firefly_js = document.createElement('script');
        firefly_js.type = 'text/javascript';
        firefly_js.setAttribute('src', `https://chat-widget-static.firefly.chat/chat.js`)
        firefly_js.setAttribute('async', '')
        head.append(firefly_js)


        var gtag_head_lp_franq = document.createElement('script');
        gtag_head_lp_franq.type = 'text/javascript';
        gtag_head_lp_franq.text = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer',"GTM-P35P9GW8");`;
        head.append(gtag_head_lp_franq);

        var gtag_body_noscript_lp_franq = document.createElement('noscript');
        var gtag_body_iframe_lp_franq = document.createElement('iframe');
        gtag_body_iframe_lp_franq.setAttribute('src', `https://www.googletagmanager.com/ns.html?id=GTM-P35P9GW8`)
        gtag_body_iframe_lp_franq.setAttribute("height", "0")
        gtag_body_iframe_lp_franq.setAttribute("width", "0")
        gtag_body_iframe_lp_franq.setAttribute("style", "display:none;visibility:hidden")
        gtag_body_noscript_lp_franq.appendChild(gtag_body_iframe_lp_franq)
        body.appendChild(gtag_body_noscript_lp_franq)
      }
    
  }
};
</script>
