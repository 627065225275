<template>
  <div class="page-principal-contact">
        <div class="container">
            <h1>{{__('Fale Conosco')}}</h1>

            <div class="row">
                <div class="col-sm-12 col-md-8">
                    <div class="description">
                        {{__("Quer entrar em contato conosco? Dúvidas sobre algum produto ou serviço da Apolar?")}}<br/>
                        {{__("Criamos este espaço especialmente para você fazer comentários, sugestões e esclarecer dúvidas.")}}
                    </div>

                    <ul>
                        <li>
                            <i class="fa fa-clock-o" aria-hidden="true"></i>
                            <div class="item">
                                <div class="title">{{__('Horário de atendimento')}}</div>
                                <div v-if="lang == 'ptBR'" class="description">De segunda à sexta-feira, entre 8h às 19h. Aos sábados, entre 9h às 14h.</div>
                                <div v-if="lang == 'es'" class="description">De lunes a viernes, de 8:30 a 17:30. Los sábados, de 9:00 a 12:00.</div>
                            </div>
                        </li>

                        <li v-if="lang == 'ptBR'">
                            <i class="fa fa-map-marker" aria-hidden="true"></i>
                            <div class="item">
                                <div class="title">{{__('Procurando uma Apolar especifica?')}}</div>
                                <div class="description">{{__('Encontre entre as nossas')}} <a target="_blank" href="https://www.apolar.com.br/franquias/">{{__('franquias')}}</a></div>
                            </div>
                        </li>
                    </ul>
                </div>
                <div class="col-sm-12 col-md-4">
                    <ContactForm :title="`Entre em contato`"/>
                </div>
            </div>
        </div>
  </div>
</template>
<script>
import Vue from "vue";
import "@/assets/scss/custom/principal/_contact.scss"
import ContactForm from '../components/apolar/principal/contact-form.vue';

export default {
  name: "faq",
  components: {
    ContactForm
  },
  metaInfo() {
    return {
      title: this.__("Fale Conosco | Imobiliária em Curitiba - Imóveis à Venda e Locação"),
      meta: [
        {
          vmid: "description",
          name: "description",
          content:"Quer ser um franqueado ou conhecer todas as nossas lojas? Entre em contato conosco econheça todo o negócio Apolar Imóveis!"
        }
      ]
    };
  },
  data() {
    return {

    };
  },
  computed: {


  },
  methods: {
    toogle_faq(faq_idx) {
        this.opened_faq = this.opened_faq == faq_idx ? null : faq_idx
    }
  },
  mounted() {
 
  }
};
</script>
